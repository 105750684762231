
	import { isEmpty } from "lodash";
	export default {
		name: "CardTextFieldToolTip",
		model: {
			prop: "model_data",
			event: "input",
		},
		props: {
			model_data: {},
			rules: {
				type: Array,
				default: function () {
					return [];
				},
			},
			hint: {
				type: String,
				default: "",
			},
			reference: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: "",
			},
			label: {
				type: [String, Object],
				default: "",
			},
			customClass: {
				type: String,
				default: "label-fixed static",
			},
			counter: {
				type: Number,
				default: 255,
			},
			is_upload: {
				type: Boolean,
				default: false,
			},
			persistent_hint: {
				type: Boolean,
				default: false,
			},
			icon_add: {
				type: Boolean,
				default: false,
			},
			clearable: {
				type: Boolean,
				default: true,
			},
			error: {
				type: Boolean,
				default: false,
			},
			messages: {
				type: String,
				default: "",
			},
			required: {
				type: Boolean,
				default: false,
			},
			type: {
				type: String,
				default: "text",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			valueNumber: {
				type: Number,
				default: undefined,
			},
			valueText: {
				type: String,
				default: undefined,
			},
			error_messages: { 
				type: Array,
				default: function () {
					return [];
				},
			},
            tooltip_message: {
                type: String,
				default: undefined,
            },
			dataCy: {
				type: String,
				default: ""
			},
		},
		data: function () {
			return {
                show: false
            };
		},
		mounted() {},
		computed: {
			isLoading() {
				return this.$store.state.proccess.loading_field;
			},
			modelData: {
				set(val: any) {
					this.$emit("input", val);
				},
				get() {
					return this.model_data;
				},
			},
			getCustomClass() {
				if (this.disabled) {
					return this.customClass + " disabled";
				} else {
					return this.customClass;
				}
			},
			getValue() {
				if (this.valueNumber) return this.valueNumber;
				if (this.valueText) return this.valueText;
				return undefined;
			},
		},
		methods: {
			isEmpty(data: any) {
				return isEmpty(data);
			},
			clickAppendOuter(params: any) {
				this.$emit("click-append-outer", params);
			},
			clickAppend() {
				this.$emit("click-append", this.modelData);
			},
			changeEvent(e: any) {
				this.$emit("change", e);
			},
			blurEvent(e: any) {
				this.$emit("blur", this.modelData);
			},
			enterHandler(e: any) {
				this.$emit("enter", e);
			},
		},
		watch: {},
	};
